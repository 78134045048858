<template>
  <div>
    <h4>My Orders</h4>

    <el-table :data="orders">
      <el-table-column label="Created At">
        <template #default="scope">
          {{ new Date(scope.row.createdAt).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column label="Total Price">
        <template #default="scope"> $ {{ scope.row.totalPrice }} </template>
      </el-table-column>
      <el-table-column label="Paid">
        <template #default="scope">
          {{ scope.row.paid ? "Yes" : "No" }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentType" label="Payment Type" />
      <el-table-column>
        <template #default="scope">
          <el-button
            v-if="!scope.row.paid"
            @click="archiveMyOrder(scope.row)"
          >
            삭제
          </el-button>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template #default="props">
          <el-row v-for="item in props.row.items" :key="item.id">
            <el-col :xs="8" :sm="3">
              {{ `${item.name} ($ ${item.price})` }}
            </el-col>
            <el-col :xs="8" :sm="3">
              {{ `x ${item.order_item.quantity}` }}
            </el-col>
            <el-col :xs="8" :sm="3">
              {{ `= $ ${item.price * item.order_item.quantity}` }}
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination 
      v-model:currentPage="currentPageNumber"
      v-model:page-size="currentPageSize"
      @current-change="retrieveOrders" 
      @size-change="retrieveOrders"
      :total="total" 
      layout="sizes, total, ->, prev, pager, next" 
    />
  </div>
</template>

<script>
import utils from "@/helper/utils.js"
import OrderService from "@/services/OrderService";

export default {
  data() {
    return {
      orders: [],
      currentPageNumber: 1,
      currentPageSize: 20,
      total: 0,
    };
  },
  methods: {
    async retrieveOrders() {
      try {
        const resp = await OrderService.getAllMy(this.currentPageNumber, this.currentPageSize);
        this.orders = resp.data.rows
        this.total = resp.data.count;
        console.log("orders: ", this.orders);
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async archiveMyOrder(order) {
      try {
        await this.$confirm(
          `정말로 해당 주문 ($ ${order.totalPrice})을 삭제하시겠습니까?`
        );
        await OrderService.archiveMyOrder(order.id);
        this.$message.success("해당 주문이 삭제되었습니다.");
        this.retrieveOrders();
      } catch (e) {
        console.log(e);
        this.message = e?.response?.data;
      }
    },
  },
  mounted() {
    this.retrieveOrders();
  },
};
</script>

<style>
</style>